/**************

Multi Digital
Bahnhofstrasse 14
9470 Buchs
Tel. 081 750 02 02
info@multidigital.ch
www.multidigital.ch

***************/

/* Schriften */
@import 'fonts';
$fontlight: Roboto-Light;
$fontregular: Roboto-Regular;
$fontitalic: Roboto-Italic;
$fontmedium: Roboto-Medium;
$fontbold: Roboto-Bold;

/* Farben */
$color1: #004176; /* neutrales blau */
$colorwasser: #4F6AAE; /* Wasser */
$colorgas: #ECBC00; /* Gas */
$colorfernwaerme: #EF861B; /* Fernwärme */
$colorkurse: #93B73A; /* Kurse & Fachtagungen */
$colorred: #E64421; /* neutrale rote Farbe */
$colorviolet: #B7ADB1; /* violet */
$colorlightgray: #E9EAEA; /* hellgrau */
$colormidlegray: #D4D5D4; /* mittelgrau */
$colorgray: #6A6A6A; /* dunkelgrau */
$colorlightgray50: #f4f5f5; /* 50% von hellgrau */
$colorwhite: #fff; /* weiss */
$colorblack: #000; /* schwarz */


* {margin:0;padding:0}
@page {size: A4 portrait; margin:20mm;}
html, body {height: 100%;}

html, html.backgroundfill, body {background-color: #ffffff; font-family: $fontregular;background-image: none;font-weight: normal;font-size:16px;color: #000;margin-top: 0cm !important;page-break-inside: auto; }

/*** Nicht drucken ***/
.topleiste, .breadcrumb, .socialmedia_share, .footer, .copyright, #svgw_toolbar, .btn-primary, .prevNext, .nach_oben, .socialmedia_links_text {
    display: none !important;
}
